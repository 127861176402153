import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { ExportToCsv } from "export-to-csv";
import Cookies from "js-cookie";

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Grid,
  Tooltip,
  IconButton,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import styles from "../styles";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((element, index) => [element, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((element) => element[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class CoolingTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: Cookies.get("k2_cooling_order") || "desc",
      orderBy: Cookies.get("k2_cooling_order_by") || "metering_point_id",
      page: 0,
      rowsPerPage: parseInt(Cookies.get("k2_cooling_rows_per_page") || "10"),
      search: "",
      redirect: false,
      id: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.meteringPoints.length !== prevProps.meteringPoints.length) {
      var page = parseInt(Cookies.get("k2_cooling_current_page") || "0");
      if (page * this.state.rowsPerPage < this.props.meteringPoints.length) {
        this.setState({page: page});
      }
    }
  }

  handleChangeRowsPerPage = (event) => {
    const { rowsPerPage, page } = this.state;
    const newRowsPerPage = event.target.value;
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    Cookies.set("k2_cooling_current_page", ""+newPage, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    Cookies.set("k2_cooling_rows_per_page", ""+newRowsPerPage, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    this.setState({ rowsPerPage: newRowsPerPage, page: newPage });
  };

  handleChangePage = (event, page) => {
    Cookies.set("k2_cooling_current_page", ""+page, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    this.setState({ page });
  };

  handleRowClick = (id) => {
    this.setState({ redirect: true, id: id });
  };

  handleRequestSort = (property) => (event) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    Cookies.set("k2_cooling_order", order, { expires: new Date(Date.now() + 1000000000000), sameSite: "lax" });
    Cookies.set("k2_cooling_order_by", orderBy, { expires: new Date(Date.now() + 1000000000000), sameSite: "lax" });
    this.setState({ order, orderBy });
  };

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  filterMeteringPoints(meteringPoints) {
    let object_period_select = "_" + this.props.period
    let filteredMeteringsPoints = [];
    for (var metering_point of meteringPoints) {
      if (
        metering_point.metering_point_id.toUpperCase().includes(this.state.search.toUpperCase())
      ) {
        var filteredMeteringsPoint = {
          metering_point_id: metering_point.metering_point_id,
          street_address: metering_point.location ? (metering_point.location.address ? metering_point.location.address.street_address : '-') : '-',
          energy: (metering_point.period_values && metering_point.period_values.energy.hasOwnProperty(object_period_select)) ? metering_point.period_values.energy[object_period_select].toFixed(3) : '-',
          flow: (metering_point.period_values && metering_point.period_values.flow.hasOwnProperty(object_period_select)) ? metering_point.period_values.flow[object_period_select].toFixed(3) : '-',
          overflow: (metering_point.period_values && metering_point.period_values.overflow.hasOwnProperty(object_period_select)) ? metering_point.period_values.overflow[object_period_select].toFixed(3) : '-',
          delta_t: (metering_point.period_values && metering_point.period_values.delta_t.hasOwnProperty(object_period_select)) ? metering_point.period_values.delta_t[object_period_select].toFixed(3) : '-',
          flow_temperature: (metering_point.period_values && metering_point.period_values.flow_temperature.hasOwnProperty(object_period_select)) ? metering_point.period_values.flow_temperature[object_period_select].toFixed(3) : '-',
          return_temperature: (metering_point.period_values && metering_point.period_values.return_temperature.hasOwnProperty(object_period_select)) ? metering_point.period_values.return_temperature[object_period_select].toFixed(3) : '-',
          calculated_at: (metering_point.period_values && metering_point.period_values.energy.calculated_at) ? metering_point.period_values.calculated_at.slice(0,10) : '-',
        };
        filteredMeteringsPoints.push(filteredMeteringsPoint);
      }
    }
    return filteredMeteringsPoints;
  }

  exportToCSV(filteredMeteringsPoints) {
    const csvOptions = {
      filename: "Cooling",
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ",",
      showLabels: true,
      showTitle: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (filteredMeteringsPoints != undefined && filteredMeteringsPoints.length > 0) {
      csvExporter.generateCsv(stableSort(filteredMeteringsPoints, getSorting(this.state.order, this.state.orderBy)));
    }
  }

  render() {
    if (this.state.redirect === true) {
      return (
        <Redirect
          push
          to={{
            pathname: "/meteringpoint/" + this.state.id,
            state: { coolingPeriod: this.props.period },
          }}
        />
      );
    }
    const { classes, intl } = this.props;

    const columns = [
      {
        id: "metering_point_id",
        align: "left",
        label: intl.formatMessage({ id: "metering_points.metering_point" }),
      },
      {
        id: "street_address",
        align: "left",
        label: intl.formatMessage({ id: "metering_points.address" }),
      },
      {
        id: "energy",
        align: "left",
        label: intl.formatMessage({ id: "energy" }),
      },
      {
        id: "flow",
        align: "left",
        label: intl.formatMessage({ id: "flow" }),
      },
      {
        id: "overflow",
        align: "right",
        label: intl.formatMessage({ id: "cooling.overflow" }),
      },
      {
        id: "delta_t",
        align: "right",
        label: intl.formatMessage({ id: "cooling.delta_t" }),
      },
      {
        id: "flow_temperature",
        align: "right",
        label: intl.formatMessage({ id: "cooling.flow_temperature" }),
      },
      {
        id: "return_temperature",
        align: "right",
        label: intl.formatMessage({ id: "cooling.return_temperature" }),
      },
      {
        id: "calculated_at",
        align: "right",
        label: intl.formatMessage({ id: "cooling.calculated_at" }),
      },
    ];
    const meteringPoints = this.filterMeteringPoints(this.props.meteringPoints);
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, meteringPoints.length - page * rowsPerPage);

    return (
      <Paper>
        <Grid container alignItems="center">
          <React.Fragment>
            <Grid item xs={9} className={classes.AlignLeft}>
              <TextField
                label={this.props.intl.formatMessage({
                  id: "cooling.filter_metering_point",
                })}
                value={this.state.search}
                onChange={this.handleSearch}
                className={classes.MarginLeft}
              />
            </Grid>
            <Grid item xs={3} className={classes.AlignRight}>
              {this.props.meteringPoints.length > 0 && meteringPoints.length > 0? (
                <Tooltip title={intl.formatMessage({ id: "cooling.download_csv" })}>
                  <IconButton onClick={() => this.exportToCSV(meteringPoints)}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
          </React.Fragment>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell key={column.id} align={column.align}>
                    <Tooltip title={intl.formatMessage({ id: "cooling.sort" })} enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={this.handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                );
              }, this)}
            </TableRow>
          </TableHead>

          {!this.props.loading ? (
            <TableBody>
              {stableSort(meteringPoints, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      key={row.metering_point_id + row.meter_reading_property}
                      onClick={(event) => this.handleRowClick(row.metering_point_id)}
                      className={classes.PointerCursor}
                    >
                      <TableCell>{row.metering_point_id}</TableCell>
                      <TableCell>{row.street_address}</TableCell>
                      <TableCell>{row.energy}</TableCell>
                      <TableCell>{row.flow}</TableCell>
                      <TableCell align="right">{row.overflow}</TableCell>
                      <TableCell align="right">{row.delta_t}</TableCell>
                      <TableCell align="right">{row.flow_temperature}</TableCell>
                      <TableCell align="right">{row.return_temperature}</TableCell>
                      <TableCell align="right">{row.calculated_at}</TableCell>
                    </TableRow>
                  );
                })}
              {10 - (rowsPerPage - emptyRows) > 0 && (
                <TableRow style={{ height: 53 * (10 - (rowsPerPage - emptyRows)) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <div align="center">
                    <Typography>
                      <FormattedMessage id="cooling.loading" />
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="div"
          count={meteringPoints.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={intl.formatMessage({ id: "cooling.rows_per_page" })}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${intl.formatMessage({ id: "cooling.of" })} ${count}`
          }
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

CoolingTable.propTypes = {
  meteringPoints: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  period: PropTypes.string.isRequired,
};

export default withStyles(styles)(injectIntl(CoolingTable));
