import React from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";

import {
  withStyles,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Dialog
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import LanguageMenu from "./LanguageMenu"; //Uncomment if you want to use again
import AppContext from "../AppContext";

import styles from "../styles";

class AccountMenu extends React.Component {
  state = { menuAnchorEl: null, showAbout: false, versions: null };

  componentDidUpdate(prevProps) {
    if (this.state.showAbout === true && this.state.versions === null) {
      this.fetchVersionNumbers();
    }
  }

  fetchVersionNumbers() {
    var thiz = this;
    var headers = { Authorization: "Bearer " + thiz.context.keycloak.token };
    thiz.context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([
            axios.get(thiz.context.config.meteringPointsAPI + "/openapi.json", { headers }),
            axios.get(thiz.context.config.meterReadingsAPI + "/openapi.json", { headers }),
            axios.get(thiz.context.config.weatherAPI + "/openapi.json", { headers }),
            axios.get(thiz.context.config.regressionAPI + "/openapi.json", { headers })
          ])
          .then(
            axios.spread(function (
              meteringPointsResponse,
              meterReadingsResponse,
              weatherResponse,
              regressionResponse
            ) {
              var versions = {};
              versions[meteringPointsResponse.data.info.title] = meteringPointsResponse.data.info.version;
              versions[meterReadingsResponse.data.info.title] = meterReadingsResponse.data.info.version;
              versions[weatherResponse.data.info.title] = weatherResponse.data.info.version;
              versions[regressionResponse.data.info.title] = regressionResponse.data.info.version;
              delete versions[undefined];
              thiz.setState({versions: versions});
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        thiz.context.keycloak.login();
      });
  }

  handleMenuOpen = event => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleMenuChoose = language => {
    this.props.updateLanguage(language);
    this.handleMenuClose();
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleManageUsers = () => {
    window.open(
      this.context.config.authBaseURL + "/admin/k2/console/#/realms/k2/users"
    );
    this.handleMenuClose();
  };

  render() {
    const frontendVersion = process.env.REACT_APP_VERSION;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Dialog
          open={this.state.showAbout && this.state.versions !== null}
          onClose={() => this.setState({showAbout: false})}
        >
          <div style={{"padding": "32px"}}>
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="K2 logo"/>
            <div style={{"textAlign": "center"}}>
              <br/>
              <div><b>Version:</b></div>
              <div>K2 Frontend: {frontendVersion}</div>
              {(this.state.versions === null) ? "" :
                Object.keys(this.state.versions).map(key => {
                  return (<div key={key}>{key}: {this.state.versions[key]} </div>)
                })
              }
            </div>
          </div>
        </Dialog>
        <IconButton
          color="inherit"
          onClick={this.handleMenuOpen}
          className={classes.MarginRight}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          id="account-menu"
          open={Boolean(this.state.menuAnchorEl)}
          onClose={this.handleMenuClose}
          anchorEl={this.state.menuAnchorEl}
        >
          <MenuItem onClick={() => this.setState({showAbout: true, versions: null})}>
            <FormattedMessage id="aboutK2" />
          </MenuItem>
          <MenuItem onClick={this.context.keycloak.accountManagement}>
            <FormattedMessage id="account" />
          </MenuItem>
          {/* Uncomment the component below to enable language settings in the account menu. */}
          {/* <LanguageMenu
            language={this.props.language}
            languages={this.props.languages}
            updateLanguage={this.props.updateLanguage}
            closeAccountMenu={this.handleMenuClose}
          /> */}
          {this.context.keycloak.role === "admin" ? (
            <MenuItem onClick={this.handleManageUsers}>
              <FormattedMessage id="manage_users" />
            </MenuItem>
          ) : null}
          <Divider />
          <MenuItem onClick={this.context.keycloak.logout}>
            <FormattedMessage id="logout" />
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

AccountMenu.contextType = AppContext;

export default withStyles(styles)(AccountMenu);
