
import React from "react";

class ChartBase extends React.Component {

    constructor(props) {
        super(props);
    }    

    updateIndicatorPlotData (data, selectedPlots, searchTag, useGroupColor) {
    for (var key in selectedPlots) {
      let selectedData = selectedPlots[key];
      let name = selectedData["name"]
      let indexOfAlreadyExisting = data.findIndex((x) => x.name === name);
      if (indexOfAlreadyExisting<0) {
        let group_id = selectedData["group_id"]
        let indexOfGroupGRaph = data.findIndex((x) => x.group_id === group_id);
        let newPlotData = this.createIndicatorPlotData(data[indexOfGroupGRaph], selectedData, searchTag, useGroupColor);
        if (typeof newPlotData != undefined) {
          data.push(newPlotData);
        }
      }      
    }
    return data;
  }

  createIndicatorPlotData (plotData, indicatorData, searchTag, useGroupColor) {

    if (indicatorData.timestamp.length<1) {
      return;
    }    
    let markedPoints = [];
    for (let arrId = 0; arrId < indicatorData.timestamp.length; ++arrId) {
      let dataId = indicatorData.index[arrId];
      markedPoints.push({
        x: plotData.x[dataId],
        y: plotData.y[dataId],
        text: new Date(indicatorData.timestamp[arrId]),
      });
    }

    let text = [];
    let x = [];
    let y = [];
    markedPoints.sort((p1,p2) => (p1.text < p2.text ? -1 : 1))
    for (const mp of markedPoints) {
      x.push(mp.x);
      y.push(mp.y);
      text.push(mp.text);
    }
    let group_id = parseInt(indicatorData.group_id)
    let color = useGroupColor ? this.props.colorPattern["group" + (group_id)] : this.props.colorPattern["marked"];
    //let color = this.props.colorPattern["marked"];
    let newPlotData = {
      type: "scatter",
      mode: "markers",
      name: indicatorData.name /*this.props.intl.formatMessage({ id: "chart.marked" })*/,
      marker: {
        color: color, symbol: this.props.chartSymbol,
        size: this.props.chartSymbolSize + 5 ,
        line: {color: this.props.colorPattern["black"], width: 1},
        opacity: 0.5
      },
      hovertemplate: "hover message",
      x: x,
      y: y,
      text: text,
      "searchTag": searchTag,
    };
    return newPlotData;
  }
}

export default ChartBase;