import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Paper, Grid, Typography,Tooltip, IconButton } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RefreshIcon from "@material-ui/icons/Refresh";
import MUIDataTable from "mui-datatables";
import axios from "axios";

import MeteringPointProtocolUploadDialog  from "./MeteringPointProtocolUploadDialog"

import ListOfChips from "./ListOFChips";
import AppContext from "../AppContext";
import localforage from 'localforage';

import { superSearch } from '@codewell/super-search';

export default function MeteringPoints(props) {
  const context = useContext(AppContext);
  const intl = useIntl();
  const [showProtocolUploadDialog, setShowProtocolUploadDialog] = useState(false);
  const toggleProtocolUploadDialog = () => {
    setShowProtocolUploadDialog(!showProtocolUploadDialog);
  }

  const [meteringPoints, setMeteringPoints] = useState([]);
  const uploadButton = () => (
    <div>
    <Tooltip placement="top" title={intl.formatMessage({id: "upload_deviations.upload_button_title"})} >
      <IconButton
        onClick={() => {
          toggleProtocolUploadDialog()
        }}>
        <CloudUploadIcon />
      </IconButton>

    </Tooltip>
    </div>
  );
  const options = {
    downloadOptions: {
      filename: "Metering_points.csv",
      separator: ";",
    },
    filterType: "multiselect",
    selectableRows: "none",
    responsive: "scrollMaxHeigh",
    elevation: 0,
    rowsPerPageOptions: [10, 20, 50],
    viewColumns: true,
    textLabels: intl.messages["MUIDataTables.textLabels"],
    onRowClick: (rowData) => {
      props.history.push("meteringpoint/" + rowData[0]);
    },
    setTableProps: () => {return {size: 'small'}},
    customToolbar: () => {
      return (
        <Tooltip
          placement="bottom"
          title={intl.formatMessage({id: "metering_points.update"})}
        >
          <IconButton
            onClick={() => {fetchAllMeteringPoints()}}
            style={{marginLeft: "0px"}}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )
    },
    customSearch: (searchText, row) => 
      superSearch({ignore: [" "]})(searchText.toLowerCase(), { ...row }).fullMatch

  };

  const columns = [
    {
      name: "metering_point_id",
      label: intl.formatMessage({ id: "metering_point.metering_point_id" }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: intl.formatMessage({ id: "metering_point.address" }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ref-period",
      label: intl.formatMessage({ id: "metering_point.ref_period" }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "tags",
      label: intl.formatMessage({ id: "metering_point.taggs" }),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <ListOfChips tags={value} />;
        },
      },
    },
    {
      name: "labels",
      label: "labels",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "deviations",
      label: "deviations",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];

  useEffect(() => {
    localforage.getItem('meteringPoints').then((value) => {
      if (value === null) {
        fetchAllMeteringPoints();
      } else {
        updateTable(value)
      }
    }).catch(() => {
      console.log("Error reading from localforage");
      fetchAllMeteringPoints();
    })
  }, []);

  function updateTable(listOfMeteringPoints) {
    let list = [];
    listOfMeteringPoints.forEach((meteringPoint) => {
      try {
        
        let address = "";
        if ("location" in meteringPoint) {
          try {
            address = meteringPoint.location.address.street_address + " " + meteringPoint.location.address.city;
          } catch (err) {}
        }
        let refPeriod = "";
        let tags = [];
        if ("tags" in meteringPoint) {
          meteringPoint.tags.forEach((tag) => {
            let key = tag.key;
            tag.val.forEach((value) => {
              tags.push(key + ":" + value);
            });
          });
        }
        if (("reference_from" in meteringPoint) && ("reference_to" in meteringPoint)) {
          refPeriod = meteringPoint.reference_from + " - " + meteringPoint.reference_to;
        } else {
          // refPeriod = "[" + "missing" + "]"
          refPeriod = intl.formatMessage({
            id: "metering_points.missing_reference_period" })
        }
        
        // parse labels and build indexing info for hidden collumn
        //
        let labels = [];
        if ("labels" in meteringPoint) {
          meteringPoint.labels.forEach((label) => {
/** Uncomment to enable description freetext search 
            var words = label.description.split(' ');
            words.forEach((word) => {
              labels.push(word);
            });
*/
            label.tags.forEach((tag) => {
              let key = tag.key;
              tag.val.forEach((value) => {
                labels.push(key + ":" + value);
              });
            });
  
          });
        }

        // parse protocols/deviations and build indexing info for hidden collumn
        //
        let deviations = []
        if ("protocols" in meteringPoint) {
          meteringPoint.protocols.forEach((protocol) => {
            deviations.push(protocol.creator)
            for (let key in protocol.deviations) {
            //protocol.deviations.forEach((deviation) => {
                let deviation = protocol.deviations[key];

                let action = deviation.action; //translate code
                let cause = deviation.cause;  //translate code
                let component = deviation.component; //translate code
                let energy_usage_change = deviation.energy_usage_change; //translate code
                let status = deviation.status; //translate code

                let actionStr = intl.formatMessage({id: "metering_point.create_protocol_action_" + action });
                let causeStr = intl.formatMessage({id: "metering_point.create_protocol_deviation_cause_" + cause });
                let componentStr = intl.formatMessage({id: "metering_point.create_protocol_" + component });
                let statusStr = intl.formatMessage({id: "metering_point.create_protocol_" + status });
                let energy_usage_changeStr = "";
                if (energy_usage_change.length>0) {
                  intl.formatMessage({id: "metering_point.create_protocol_energy_usage_change_" + energy_usage_change });
                }
/**             Uncomment to enable freetext search
                let action_other_str = deviation.action_other; //freetext 
                let component_other_str = deviation.component_other; //freetext
                let fault_description_str = deviation.fault_description; //freetext
                let pushvalues = [actionStr, causeStr, componentStr, statusStr, energy_usage_changeStr];
                let pushvalues = [action_other_str, component_other_str, fault_description_str, actionStr, causeStr, componentStr, statusStr, energy_usage_changeStr];
                for (let val in pushvalues) {
                  val = pushvalues[val];
                  if (val.length>0) {
                    deviations.push(val)
                  }
                }
*/
              }
  
            });
  
        }


        list.push([meteringPoint.metering_point_id, address, refPeriod, tags, labels, deviations]);
      } catch (err) {
        console.log(err);
      }
    });
    setMeteringPoints(list);
  }

  function fetchMeteringPoints(token) {
    var headers = { Authorization: "Bearer " + token };
    var params = { limit: 9999999999 };
    return axios.get(context.config.meteringPointsAPI + "/", { headers, params });
  }

  async function fetchAllMeteringPoints() {
    context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([fetchMeteringPoints(context.keycloak.token)])
          .then(
            axios.spread(function (meteringPointResponse) {
              localforage.setItem('meteringPoints', meteringPointResponse.data);
              updateTable(meteringPointResponse.data);
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        context.keycloak.login();
      });
  }

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            <FormattedMessage id="metering_points.metering_points" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <div>
                {uploadButton()}
            </div>
            <MUIDataTable data={meteringPoints} columns={columns} options={options} />
          </Paper>
        </Grid>
      </Grid>
      <MeteringPointProtocolUploadDialog
                showProtocolUploadDialog = {showProtocolUploadDialog}
                toggleProtocolUploadDialog = {toggleProtocolUploadDialog}
                fetchAllMeteringPoints = {fetchAllMeteringPoints}
            />
    </React.Fragment>
  );
}
